var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col sm:flex-row gap-2 sm:items-center" },
    [
      _c("div", { staticClass: "w-full" }, [
        _c("form", { staticClass: "w-full", attrs: { id: "payment-form" } }, [
          _c("div", { attrs: { id: "payment-element" } }),
          _vm.message
            ? _c("div", { attrs: { id: "payment-message" } }, [
                _vm._v(_vm._s(_vm.message)),
              ])
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }