var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [_vm._t("default", null, { id: _vm.intentId, publicKey: _vm.publicKey })],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }