<template>
  <div v-if="hashLoaded">
    <payment-intent-provider
      @loading="$vs.loading()"
      @loaded="$vs.loading.close()"
      :model-id="_.get(model, 'id')"
      :service="service"
      @secret="injectSecret"
      @intent="paymentIntent = $event"
      :intent-type="mode"
      :disabled="gatewayMercadoPago"
    >
      <template v-slot:default="intentProvider">
        <product-checkout
          ref="checkout"
          :publicKey="intentProvider.publicKey"
          :hash="hash"
          :model="model"
          :service="service"
          :loading="loading"
          @submit="proccessCheckout"
          @input="updateMetadata"
          :mode="mode"
          :gateway="gateway"
          :disabled="!termsAccepted"
          :redirect="_.get(model, 'redirect_url')"
          :terms-accepted="termsAccepted">
          <div>
            <vx-card>
              <div class="flex flex-col xl:gap-4 p-4">
                <label class="font-bold text-base xl:text-2xl text-center">{{ _.get(model, 'name') }} </label>
                <vs-divider class="w-full"/>
                <label class="font-bold text-4xl text-center"
                  >{{ priceValueFormatted }}
                </label>
              </div>
            </vx-card>

            <vx-card class="mt-4">
              <div class="flex flex-col xl:gap-4 p-4">
                <div class="__description">
                  <label class="text-sm xl:text-base text-center"
                    >
                    <div v-html-safe="_.get(model, 'description')"/>
                  </label>
                </div>
              </div>
            </vx-card>
          </div>
          <template v-slot:info>
            <div class="pt-2 flex flex-wrap justify-left">
              <vs-checkbox v-model="termsAccepted" class="mb-3">{{ $t('eu-li-e-concordo-com-estes') }}</vs-checkbox>
              <a :href="model.terms_url || 'https://educatena.com.br/terms-service'" target="_blank" class="float-left">{{ $t('termos-e-condicoes') }}</a>
            </div>
          </template>
        </product-checkout>
      </template>
    </payment-intent-provider>

  </div>

</template>

<script>
import PlanService from '@/services/api/PlanService'
import ProductCheckout from './ProductCheckout.vue'
import PaymentIntentProvider from './PaymentIntentProvider.vue'

export default {
  name: 'plan-checkout-page',
  components: { ProductCheckout, PaymentIntentProvider },
  props: {
    hash: {
      type: String,
      default: null,
    },
    mode: {
      type: String,
      default: 'payment'
    }
  },

  data: () => ({
    // State Flags
    secretInjected: false,
    hashLoaded: false,

    // Utilities
    currencyFormatter: null,

    // Service
    service: null,

    // Data
    model: {
      id: null,
      name: null,
      description: null,
      price: {
        stripe_id: null,
        value: null,
        currency_type: null,
      },
      redirect_url: null,
      termsAccepted: false,
      terms_url: null,
      gateway: null
    },
    termsAccepted: false,

    paymentIntent: null
  }),

  computed: {
    priceValueFormatted() {
      if (this.model.price.value)
        // eslint-disable-next-line nonblock-statement-body-position
        return this.currencyFormatter.format(this.model.price.value)
      else return null
    },
    gateway() {
      return this.model.gateway
    },
    gatewayMercadoPago() {
      return this.gateway === 'omnipay/mercado-pago'
    },
    gatewayStripe() {
      return this.gateway === 'educatena/stripe'
    }
  },

  watch: {
    termsAccepted(val) {
      this.$root.$emit('checkout-terms', val)
    }
  },

  methods: {
    // Utilities
    checkoutComponent() {
      return this.$refs.checkout || null
    },
    updateMetadata(payload) {
      if (this.paymentIntent && this.model.id) {
        this.service.updateMetadata(this.model.id, this.paymentIntent, payload)
      }
    },
    // Component Lifecycle
    clearForm() {
      const clearPayment = this.$refs.payment && this.$refs.payment.clear
      if (clearPayment && this._.isFunction(clearPayment)) {
        clearPayment()
      }

      const clearStripe = this.$refs.checkout && this.$refs.checkout.$refs.payment && this.$refs.checkout.$refs.payment.clear
      if (clearStripe && this._.isFunction(clearStripe)) {
        clearStripe()
      }

      const clearMercadoPago = this.$refs.checkout && this.$refs.checkout.$refs['mercado-pago-payment'] && this.$refs.checkout.$refs['mercado-pago-payment'].clear
      if (clearMercadoPago && this._.isFunction(clearMercadoPago)) {
        clearMercadoPago()
      }

      this.name = ''
      this.email = ''
      this.phone = ''
    },
    // Main Action
    proccessCheckout(response, billingDetails) {
      if (this.gatewayStripe) {
        this.proccessStripeCheckout(response, billingDetails)
      } else if (this.gatewayMercadoPago) {
        this.proccessMercadoPagoCheckout(response, billingDetails)
      }
    },
    proccessMercadoPagoCheckout(response, billingDetails) {
      this.service.successMercadoPagoPayment(this.model.id, response, billingDetails).then(
        response => {
          console.log('success', response)
          this.clearForm()
          const redirect = this._.get(this.model, 'redirect_url')
          console.log('redirect', redirect)
          window.location.replace(redirect)
        },
        error => {
          this.clearForm()
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        }
      )
    },
    proccessStripeCheckout(response, billingDetails) {
      console.log('handling checkout respnse', response, billingDetails)
      const intent = this._.get(response, 'paymentIntent')
      const redirectUrl = this._.get(response, 'redirect')
      const error = this._.get(response, 'error')

      if (error !== null) {
        this.$vs.loading.close()
      }

      billingDetails.termsAccepted = this.termsAccepted

      if (intent && !error) {
        this.service.successStripePayment(this.model.id, intent, billingDetails).then(
          response => {
            console.log('Processed successfull payment with success!!!', response)
            this.clearForm()
          }
        ).finally(() => {
          this.$destroy()
          window.location.replace(redirectUrl)
        })
      }
    },
    // Setup
    injectSecret(secret) {
      const checkout = this.checkoutComponent()

      if (this._.isString(secret) && this._.isFunction(checkout.loadSecret)) {
        checkout.loadSecret(secret)
      }
    }
  },

  beforeMount() {
    this.service = PlanService.build(this.$vs)

    this.currencyFormatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })

    if (this.hash) {
      this.$vs.loading()
      this.service
        .lookupHash(this.hash)
        .then(
          (response) => {
            this.model = response
            this.hashLoaded = true
          },
          (error) => {
            this.notifyError(this.$vs, this.$t('nao-foi-possivel-carregar-os-dados'))
          }
        )
        .finally(() => {
          this.$vs.loading.close()
        })
    }
  },

  beforeDestroy() {
    this.$off('model-loaded')
  }
}
</script>

<style lang="scss">
._plan-checkout-page {
  & .__right-side, .__left-side {
    & .vx-card .vx-card__body {
      // max-height: 50rem !important;
    }
  }

  & .__left-side {
    & .__description {
      max-height: 32rem !important;
      @apply overflow-y-auto;
    }
  }
}
</style>
