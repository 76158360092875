var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-item", class: { "-active": _vm.isCardFlipped } },
    [
      _c("div", { staticClass: "card-item__side -front" }, [
        _c("div", {
          ref: "focusElement",
          staticClass: "card-item__focus",
          class: { "-active": _vm.focusElementStyle },
          style: _vm.focusElementStyle,
        }),
        _c("div", { staticClass: "card-item__cover" }, [
          _vm.currentCardBackground
            ? _c("img", {
                staticClass: "card-item__bg",
                attrs: { src: _vm.currentCardBackground },
              })
            : _vm._e(),
        ]),
        _c("div", { staticClass: "card-item__wrapper" }, [
          _c("div", { staticClass: "card-item__top" }, [
            _c("img", {
              staticClass: "card-item__chip",
              attrs: {
                src: "https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/chip.png",
              },
            }),
            _c(
              "div",
              { staticClass: "card-item__type" },
              [
                _c("transition", { attrs: { name: "slide-fade-up" } }, [
                  _vm.cardType
                    ? _c("img", {
                        key: _vm.cardType,
                        staticClass: "card-item__typeImg",
                        attrs: {
                          src:
                            "https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/" +
                            _vm.cardType +
                            ".png",
                          alt: "",
                        },
                      })
                    : _vm._e(),
                ]),
              ],
              1
            ),
          ]),
          _c(
            "label",
            {
              ref: _vm.fields.cardNumber,
              staticClass: "card-item__number",
              attrs: { for: _vm.fields.cardNumber },
            },
            [
              _vm._l(_vm.currentPlaceholder, function (n, $index) {
                return _c(
                  "span",
                  { key: $index },
                  [
                    _c("transition", { attrs: { name: "slide-fade-up" } }, [
                      _vm.getIsNumberMasked($index, n)
                        ? _c("div", { staticClass: "card-item__numberItem" }, [
                            _vm._v("*"),
                          ])
                        : _vm.labels.cardNumber.length > $index
                        ? _c(
                            "div",
                            {
                              key: _vm.currentPlaceholder,
                              staticClass: "card-item__numberItem",
                              class: { "-active": n.trim() === "" },
                            },
                            [_vm._v(_vm._s(_vm.labels.cardNumber[$index]))]
                          )
                        : _c(
                            "div",
                            {
                              key: _vm.currentPlaceholder + 1,
                              staticClass: "card-item__numberItem",
                              class: { "-active": n.trim() === "" },
                            },
                            [_vm._v(_vm._s(n))]
                          ),
                    ]),
                  ],
                  1
                )
              }),
            ],
            2
          ),
          _c("div", { staticClass: "card-item__content" }, [
            _c(
              "label",
              {
                ref: _vm.fields.cardName,
                staticClass: "card-item__info",
                attrs: { for: _vm.fields.cardName },
              },
              [
                _c("div", { staticClass: "card-item__holder" }, [
                  _vm._v(
                    _vm._s(_vm.$t("interactive_paycard.card.card_holder"))
                  ),
                ]),
                _c("transition", { attrs: { name: "slide-fade-up" } }, [
                  _vm.labels.cardName.length
                    ? _c(
                        "div",
                        { key: "1", staticClass: "card-item__name" },
                        [
                          _c(
                            "transition-group",
                            { attrs: { name: "slide-fade-right" } },
                            _vm._l(
                              _vm.labels.cardName.replace(/\s\s+/g, " "),
                              function (n, $index) {
                                return _c(
                                  "span",
                                  {
                                    key: $index + 1,
                                    staticClass: "card-item__nameItem",
                                  },
                                  [_vm._v(_vm._s(n))]
                                )
                              }
                            ),
                            0
                          ),
                        ],
                        1
                      )
                    : _c("div", { key: "2", staticClass: "card-item__name" }, [
                        _vm._v(
                          _vm._s(_vm.$t("interactive_paycard.card.full_name"))
                        ),
                      ]),
                ]),
              ],
              1
            ),
            _c("div", { ref: "cardDate", staticClass: "card-item__date" }, [
              _c(
                "label",
                {
                  staticClass: "card-item__dateTitle",
                  attrs: { for: _vm.fields.cardMonth },
                },
                [_vm._v(_vm._s(_vm.$t("interactive_paycard.card.expires")))]
              ),
              _c(
                "label",
                {
                  staticClass: "card-item__dateItem",
                  attrs: { for: _vm.fields.cardMonth },
                },
                [
                  _c("transition", { attrs: { name: "slide-fade-up" } }, [
                    _vm.labels.cardMonth
                      ? _c("span", { key: _vm.labels.cardMonth }, [
                          _vm._v(_vm._s(_vm.labels.cardMonth)),
                        ])
                      : _c("span", { key: "2" }, [
                          _vm._v(_vm._s(_vm.$t("interactive_paycard.card.mm"))),
                        ]),
                  ]),
                ],
                1
              ),
              _vm._v(" / "),
              _c(
                "label",
                {
                  staticClass: "card-item__dateItem",
                  attrs: { for: "cardYear" },
                },
                [
                  _c("transition", { attrs: { name: "slide-fade-up" } }, [
                    _vm.labels.cardYear
                      ? _c("span", { key: _vm.labels.cardYear }, [
                          _vm._v(
                            _vm._s(String(_vm.labels.cardYear).slice(2, 4))
                          ),
                        ])
                      : _c("span", { key: "2" }, [
                          _vm._v(_vm._s(_vm.$t("interactive_paycard.card.yy"))),
                        ]),
                  ]),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "card-item__side -back" }, [
        _c("div", { staticClass: "card-item__cover" }, [
          _vm.currentCardBackground
            ? _c("img", {
                staticClass: "card-item__bg",
                attrs: { src: _vm.currentCardBackground },
              })
            : _vm._e(),
        ]),
        _c("div", { staticClass: "card-item__band" }),
        _c("div", { staticClass: "card-item__cvv" }, [
          _c("div", { staticClass: "card-item__cvvTitle" }, [_vm._v("CVV")]),
          _c(
            "div",
            { staticClass: "card-item__cvvBand" },
            _vm._l(_vm.labels.cardCvv, function (n, $index) {
              return _c("span", { key: $index }, [_vm._v("*")])
            }),
            0
          ),
          _c("div", { staticClass: "card-item__type" }, [
            _vm.cardType
              ? _c("img", {
                  staticClass: "card-item__typeImg",
                  attrs: {
                    src:
                      "https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/" +
                      _vm.cardType +
                      ".png",
                  },
                })
              : _vm._e(),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }