<template>
  <div>
    <interactive-paycard v-model="card">
      <template #submit="submit">
        <div class="pt-2 flex flex-row w-full justify-end">
          <input
            :disabled="submit.state.invalid || disabled"
            class="vs-component vs-button vs-button-primary vs-button-filled includeIcon"
            type="submit"
            @click="submit.actions.invaildCard"
            value="Enviar"
          />
        </div>
      </template>
    </interactive-paycard>
    <!-- <simple-pay-card/> -->
  </div>
</template>

<script>
import loadScript from 'load-script'
import InteractivePaycard from '@/components/vue-interactive-paycard/InteractivePaycard.vue'

export default {
  components: { InteractivePaycard },
  props: {
    publicKey: {
      default: null,
      type: String
    },
    model: {
      default: () => ({
        id: null,
        name: null,
        description: null,
        price: {
          stripe_id: null,
          value: null,
          currency_type: null,
        },
        redirect_url: null,
        termsAccepted: false,
        terms_url: null,
        gateway: null
      }),
      type: Object
    },
  },
  data: () => ({
    disabled: true,
    card: {
      cardName: '',
      cardNumber: '',
      cardNumberNotMask: '',
      cardMonth: '',
      cardYear: '',
      cardCvv: ''
    },
    gatewayInstance: null,
    gatewayForm: null
  }),
  beforeMount() {
    loadScript('https://sdk.mercadopago.com/js/v2', () => {
      this.$nextTick(this.gatewayBoot)
    })
    this.$root.$on('checkout-terms', this.updateDisable)
  },
  beforeDestroy() {
    this.$root.$off('checkout-terms', this.updateDisable)
  },
  methods: {
    updateDisable(accepted) {
      this.disabled = !accepted
    },
    gatewayBoot() {
      if (this.publicKey) {
        // eslint-disable-next-line no-undef
        this.gatewayInstance = new MercadoPago(this.publicKey, {
          locale: 'pt-BR',
        })
      }

      if (this.gatewayInstance) {
        this.setupGatewayElements()
      }
    },
    setupGatewayElements() {
      this.gatewayForm = this.gatewayInstance.cardForm({
        amount: this.model.price.value,
        autoMount: true,
        form: {
          id: 'card-form',
          cardholderName: {
            id: 'v-card-name',
            placeholder: this.$t('titular-do-cartao'),
          },
          cardNumber: {
            id: 'v-card-number',
            placeholder: this.$t('numero-do-cartao'),
          },
          cardExpirationMonth: {
            id: 'v-card-month',
            placeholder: 'MM'
          },
          cardExpirationYear: {
            id: 'v-card-year',
            placeholder: 'YYYY'
          },
          securityCode: {
            id: 'v-card-cvv',
            placeholder: this.$t('codigo-de-seguranca'),
          },
          installments: {
            id: 'form-checkout__installments',
            placeholder: this.$t('fields.installments'),
          },
          identificationType: {
            id: 'form-checkout__identificationType',
            placeholder: this.$t('tipo-de-documento'),
          },
          identificationNumber: {
            id: 'form-checkout__identificationNumber',
            placeholder: this.$t('numero-do-documento'),
          },
          issuer: {
            id: 'form-checkout__issuer',
            placeholder: this.$t('banco-emissor'),
          },
        },
        callbacks: {
          onFormMounted: error => {
            if (error) return console.warn('Form Mounted handling error: ', error)
            console.log('Form mounted')
          },
          onSubmit: event => {
            this.$vs.loading()
            event.preventDefault()

            console.log('submit is here... event:', event)

            const {
              paymentMethodId: payment_method_id,
              issuerId: issuer_id,
              cardholderEmail: email,
              amount,
              token,
              installments,
              identificationNumber,
              identificationType,
            } = this.gatewayForm.getCardFormData()

            const eventPayload = {
              token,
              issuer_id,
              payment_method_id,
              transaction_amount: Number(amount),
              installments: Number(installments),
              description: this.$t('descricao-do-produto'),
              payer: {
                email,
                identification: {
                  type: identificationType,
                  number: identificationNumber,
                },
              },
            }
            this.$emit('success', eventPayload)
          },
          onCardTokenReceived: (error, token) => {
            const errors = error
            console.log('card token received! token:', token, 'errors:', errors)

            if (errors && this._.isArray(errors) && errors.length > 0) {
              errors.forEach((error) => {
                this.notifyError(this.$vs, this._.get(error, 'message'), 3000)
              })
            }
          },
          onFetching: (resource) => {
            console.log('Fetching resource: ', resource)
          }
        },
      })
    }
  }
}
</script>

<style>
</style>