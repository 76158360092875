<template>
  <div class="h-screen flex w-full bg-img">
    <div class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
      <div class="flex justify-items-center mb-2 pt-2">
        <img src="@/assets/images/logo/logo.png" alt="logo" class="mx-auto" style="max-width: 300px;"/>
      </div>
      <img src="@/assets/images/pages/500.png" alt="graphic-500" class="mx-auto mb-4 max-w-full" />
      <h1 class="sm:mx-0 mx-4 mb-6 text-5xl d-theme-heading-color">500 - {{ title }}</h1>
      <p class="sm:mx-0 mx-4 mb-4 sm:mb-8 d-theme-text-inverse">
        {{ message }}
      </p>
      <vs-button size="large" @click="back">{{ $t('common.back') }}</vs-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: function () {
        return this.$t('dados-invalidos')
      }
    },
    message: {
      type: String,
      default: function () {
        return this.$t('nao-foi-possivel-carregar-os-dados-infor')
      }
    }
  },
  methods: {
    back() {
      const userInfo = localStorage.getItem('userInfo')
      if (userInfo) {
        this.$router.push('/')
      } else {
        this.$router.push('/login')
      }
    }
  }
}
</script>

<style lang="scss">
._error-500.__error-panel {
  @apply flex items-center justify-center w-full h-full;
  min-height: 80vh;
}
</style>