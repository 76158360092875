<template>
  <div>
    <slot :id="intentId" :publicKey="publicKey">
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    modelId: {
      type: Number,
      default: null
    },
    intentType: {
      type: String, 
      default: 'payment'
    },
    service: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    publicKey: null,
    intentId: null,
    success: false
  }),
  mounted() {
    if (this._.isFunction(this.service.getIntent) && this.modelId && !this.disabled) {
      this.$emit('loading')
      this.service.getIntent(this.intentType, this.modelId).then(
        response => {
          this.success = true
          this.$emit('secret', this._.get(response, 'client_secret'))
          this.intentId = this._.get(response, 'intent_id')
          this.publicKey = this._.get(response, 'public_key')
          this.$emit('intent', this.intentId)
        },
        error => {
          this.success = false
          console.error(error)
          this.$emit('error', error)
        }
      ).finally(
        () => {this.$emit('loaded', this.success)}
      )
    }
  }
}
</script>

<style>

</style>