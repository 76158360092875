<template>
  <form id="card-form" class="card-form" @submit="formSubmit">
    <div class="card-list">
      <Card
        :fields="fields"
        :labels="formData"
        :isCardNumberMasked="isCardNumberMasked"
        :randomBackgrounds="randomBackgrounds"
        :backgroundImage="backgroundImage"
      />
    </div>
    <div class="card-form__inner">
      <div class="card-input">
        <label for="cardNumber" class="card-input__label">{{ $t('interactive_paycard.card_form.card_number') }}</label>
        <div>
          <div>
            <input
              name="card_number"
              v-validate="'required'"
              type="tel"
              :id="fields.cardNumber"
              @input="changeNumber"
              class="card-input__input"
              :value="formData.cardNumber"
              :maxlength="cardNumberMaxLength"
              data-card-field
              autocomplete="off"
            />
            <span class="text-danger text-sm" v-show="errors.has('card_number')">{{
                errors.first('card_number')
            }}</span>
            <button
              class="card-input__eye"
              :class="{ '-active' : !isCardNumberMasked }"
              :title="$t('mostrar-esconder-numero-cartao')"
              tabindex="-1"
              :disabled="formData.cardNumber === ''"
              @click="toggleMask"
            ></button>
          </div>
          <div class="hidden">
            <select class="card-input__input -select w-2/5" name="issuer" id="form-checkout__issuer"></select>
          </div>
        </div>
      </div>
      <div class="card-input">
        <label for="cardName" class="card-input__label">{{ $t('interactive_paycard.card_form.card_name') }}</label>
        <input
          name="card_name"
          type="text"
          :id="fields.cardName"
          v-letter-only
          @input="changeName"
          class="card-input__input"
          :value="formData.cardName"
          data-card-field
          autocomplete="off"
          v-validate="'alpha_spaces|required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('card_name')">{{
            errors.first('card_name')
        }}</span>
      </div>
      <div class="card-input">
        <label for="form-checkout__identificationNumber" class="card-input__label">Identificação</label>
        <div class="flex flex-row gap-2">
          <select class="card-input__input -select w-2/5" v-validate="'required'" name="identificationType" id="form-checkout__identificationType"></select>          
          <span class="text-danger text-sm" v-show="errors.has('identificationType')">{{
            errors.first('identificationType')
          }}</span>
          <input type="text" v-validate="'required|numeric'" class="card-input__input" name="identificationNumber" id="form-checkout__identificationNumber"/>
          <span class="text-danger text-sm" v-show="errors.has('identificationNumber')">{{
            errors.first('identificationNumber')
          }}</span>
        </div>
      </div>
      <div class="card-form__row">
        <div class="card-form__col">
          <div class="card-form__group">
            <label for="cardMonth" class="card-input__label">{{ $t('interactive_paycard.card_form.expiration_date') }}</label>
            <select
              class="card-input__input -select"
              :id="fields.cardMonth"
              v-model="formData.cardMonth"
              @change="changeMonth"
              data-card-field
            >
              <option value disabled selected>{{ $t('interactive_paycard.card_form.month') }}</option>
              <option
                v-bind:value="n < 10 ? '0' + n : n"
                v-for="n in 12"
                v-bind:disabled="n < minCardMonth"
                v-bind:key="n"
              >{{generateMonthValue(n)}}</option>
            </select>
            <select
              class="card-input__input -select"
              :id="fields.cardYear"
              v-model="formData.cardYear"
              @change="changeYear"
              data-card-field
            >
              <option value disabled selected>{{ $t('interactive_paycard.card_form.year') }}</option>
              <option
                v-bind:value="$index + minCardYear"
                v-for="(n, $index) in 12"
                v-bind:key="n"
              >{{$index + minCardYear}}</option>
            </select>
          </div>
        </div>
        <div class="card-form__col -cvv">
          <div class="card-input">
            <label for="cardCvv" class="card-input__label">{{ $t('interactive_paycard.card_form.cvv') }}</label>
            <input
              name="cvv"
              type="tel"
              class="card-input__input"
              v-number-only
              :id="fields.cardCvv"
              maxlength="4"
              :value="formData.cardCvv"
              @input="changeCvv"
              data-card-field
              autocomplete="off"
              v-validate="'required|numeric'"
            />
            <span class="text-danger text-sm" v-show="errors.has('cvv')">{{
              errors.first('cvv')
            }}</span>
          </div>
        </div>
      </div>
      <div class="card-form__row">
        <div class="card-form__col">
          <select class="card-input__input -select" name="installments" id="form-checkout__installments"></select>
        </div>
      </div>
      
      <slot name="submit" :actions="{invaildCard}" :state="{invalid: invalidForm}">
        <button class="card-form__button" v-on:click="invaildCard">{{ $t('interactive_paycard.card_form.submit') }}</button>
      </slot>

    </div>
  </form>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import Card from './Card'
export default {
  name: 'CardForm',
  directives: {
    'number-only': {
      bind (el) {
        function checkValue (event) {
          event.target.value = event.target.value.replace(/[^0-9]/g, '')
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true
          }
          event.preventDefault()
        }
        el.addEventListener('keypress', checkValue)
      }
    },
    'letter-only': {
      bind (el) {
        function checkValue (event) {
          if (event.charCode >= 48 && event.charCode <= 57) {
            event.preventDefault()
          }
          return true
        }
        el.addEventListener('keypress', checkValue)
      }
    }
  },
  props: {
    formData: {
      type: Object,
      default: () => {
        return {
          cardName: '',
          cardNumber: '',
          cardNumberNotMask: '',
          cardMonth: '',
          cardYear: '',
          cardCvv: ''
        }
      }
    },
    backgroundImage: [String, Object],
    randomBackgrounds: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Card,
    ValidationProvider,
    ValidationObserver,
  },
  data () {
    return {
      fields: {
        cardNumber: 'v-card-number',
        cardName: 'v-card-name',
        cardMonth: 'v-card-month',
        cardYear: 'v-card-year',
        cardCvv: 'v-card-cvv'
      },
      minCardYear: new Date().getFullYear(),
      isCardNumberMasked: true,
      mainCardNumber: this.cardNumber,
      cardNumberMaxLength: 19
    }
  },
  computed: {
    minCardMonth () {
      if (this.formData.cardYear === this.minCardYear) return new Date().getMonth() + 1
      return 1
    },
    invalidForm() {
      return this.errors.any() || (
        this.isEmpty(this.formData.cardNumber) ||
        this.isEmpty(this.formData.cardName) ||
        this.isEmpty(this.formData.cardMonth) ||
        this.isEmpty(this.formData.cardYear) ||
        this.isEmpty(this.formData.cardCvv)
      )
    }
  },
  watch: {
    cardYear () {
      if (this.formData.cardMonth < this.minCardMonth) {
        this.formData.cardMonth = ''
      }
    }
  },
  mounted () {
    this.maskCardNumber()
  },
  methods: {
    formSubmit(event) {
      console.log('form has been submit!', event)
    },
    generateMonthValue (n) {
      return n < 10 ? `0${n}` : n
    },
    changeName (e) {
      this.formData.cardName = e.target.value
      this.$emit('input-card-name', this.formData.cardName)
    },
    changeNumber (e) {
      this.formData.cardNumber = e.target.value
      let value = this.formData.cardNumber.replace(/\D/g, '')
      // american express, 15 digits
      if ((/^3[47]\d{0,13}$/).test(value)) {
        this.formData.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ')
        this.cardNumberMaxLength = 17
      } else if ((/^3(?:0[0-5]|[68]\d)\d{0,11}$/).test(value)) { // diner's club, 14 digits
        this.formData.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ')
        this.cardNumberMaxLength = 16
      } else if ((/^\d{0,16}$/).test(value)) { // regular cc number, 16 digits
        this.formData.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{4})/, '$1 $2 ').replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ')
        this.cardNumberMaxLength = 19
      }
      // eslint-disable-next-line eqeqeq
      if (e.inputType == 'deleteContentBackward') {
        let lastChar = this.formData.cardNumber.substring(this.formData.cardNumber.length, this.formData.cardNumber.length - 1)
        // eslint-disable-next-line eqeqeq
        if (lastChar == ' ') { this.formData.cardNumber = this.formData.cardNumber.substring(0, this.formData.cardNumber.length - 1) }
      }
      this.$emit('input-card-number', this.formData.cardNumber)
    },
    changeMonth () {
      this.$emit('input-card-month', this.formData.cardMonth)
    },
    changeYear () {
      this.$emit('input-card-year', this.formData.cardYear)
    },
    changeCvv (e) {
      this.formData.cardCvv = e.target.value
      this.$emit('input-card-cvv', this.formData.cardCvv)
    },
    invaildCard () {
      let number = this.formData.cardNumberNotMask.replace(/ /g, '')
      var sum = 0
      for (var i = 0; i < number.length; i++) {
        var intVal = parseInt(number.substr(i, 1))
        if (i % 2 === 0) {
          intVal *= 2
          if (intVal > 9) {
            intVal = 1 + (intVal % 10)
          }
        }
        sum += intVal
      }
      if (sum % 10 !== 0) {
        alert(this.$t('interactive_paycard.cardForm.invalidCardNumber'))
      }
    },
    blurCardNumber () {
      if (this.isCardNumberMasked) {
        this.maskCardNumber()
      }
    },
    maskCardNumber () {
      this.formData.cardNumberNotMask = this.formData.cardNumber
      this.mainCardNumber = this.formData.cardNumber
      let arr = this.formData.cardNumber.split('')
      arr.forEach((element, index) => {
        if (index > 4 && index < 14 && element.trim() !== '') {
          arr[index] = '*'
        }
      })
      this.formData.cardNumber = arr.join('')
    },
    unMaskCardNumber () {
      this.formData.cardNumber = this.mainCardNumber
    },
    focusCardNumber () {
      this.unMaskCardNumber()
    },
    toggleMask () {
      this.isCardNumberMasked = !this.isCardNumberMasked
      if (this.isCardNumberMasked) {
        this.maskCardNumber()
      } else {
        this.unMaskCardNumber()
      }
    }
  }
}
</script>
