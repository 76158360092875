<template>
  <div>
    <div
      :class="[
        '_plan-checkout-page',
        'flex lg:flex-row flex-col',
        'p-2',
        'md:pt-4 xl:w-5/6 xl:mx-auto gap-2 md:gap-6',
        {
          'hidden': !secretInjected
        }
      ]"
    >
      <div class="__left-side __side w-full">
        <slot>
        </slot>
      </div>
      <div class="__right-side __side w-full">
        <vx-card class="__card">
          <div class="relative h-full">
            <div class="flex gap-2 justify-between flex-col h-full">
              <div class="w-full">
                <vs-input
                  :disabled="!secretInjected || disableFieldActiveUser"
                  :label="$t('name')"
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  id="name"
                  name="name"
                  v-model="name"
                  class="w-full stripe-label stripe-focus"
                />
                <span class="text-danger text-sm">{{ errors.first('name') }}</span>
              </div>
              <div class="w-full">
                <vs-input
                  :disabled="!secretInjected || disableFieldActiveUser"
                  type="email"
                  name="email"
                  v-model="email"
                  class="w-full stripe-label stripe-focus"
                  label="E-Mail"
                  v-validate="'required|email'"
                  data-vv-validate-on="blur"
                />
                <span class="text-danger text-sm">{{ errors.first('email') }}</span>
              </div>
              <div class="flex flex-col gap-1 w-full">
                <!-- <vs-input
                  :disabled="!secretInjected"
                  autocomplete="off"
                  name="user_phone"
                  class="w-full stripe-label stripe-focus"
                  label="Telefone/Celular"
                  v-validate="'max:20|numeric|required'"
                  data-vv-as="Telefone/Celular"
                  data-vv-validate-on="blur"
                  v-model="phone"/> -->
                <MazPhoneNumberInput
                  v-model="phone"
                  default-country-code="BR"
                  :translations="{
                    countrySelectorLabel: 'Código do País',
                    countrySelectorError: 'Pais Inválido',
                    phoneNumberLabel: 'Número de Telefone',
                    example: 'Exemplo:'
                  }"
                />
              </div>

              <fieldset ref="stripe-fieldset" v-if="gatewayStripe">
                <legend>{{ $t('forma-de-pagamento') }}</legend>
                <stripe-payment
                  class="p-2"
                  :mode="mode"
                  ref="payment"
                  :secret="secret"
                  :publicKey="publicKey"
                  @success="checkoutSuccess"
                  @loaded="stripePaymentLoaded"
                />
              </fieldset>

              <mercado-pago-payment
                v-else-if="gatewayMercadoPago"
                ref="mercado-pago-payment"
                :model="model"
                :public-key="model.gateway_key"
                @success="checkoutSuccess"
              />

              <slot name="info">
              </slot>
              <div class="w-full" v-if="!gatewayMercadoPago">
                <vs-button
                  :disabled="invalidForm || !secretInjected || disabled"
                  icon="shopping_cart"
                  class="w-full"
                  @click="proccessCheckout"
                >
                  {{ $t('confirmar') }}
                </vs-button>
              </div>
            </div>
          </div>
        </vx-card>

        <div class="flex items-center w-full pt-6">
          <span class="text-center w-full text-xs"
            >{{ $t('powered-by-educatena-stripe') }}</span
          >
        </div>
      </div>
    </div>

    <error-500
      v-if="missingSecret"
      :title="$t('chave-ausente')"
      :message="$t('nao-e-possivel-iniciar-sessao-segura')"
    />
  </div>

</template>

<script>
import { MazPhoneNumberInput } from 'maz-ui'
import Error500 from '@/views/pages/Error500.vue'
import StripePayment from '@/components/stripe/StripePayment.vue'
import MercadoPagoPayment from './mercado-pago/MercadoPagoPayment.vue'

export default {
  components: {Error500, StripePayment, MazPhoneNumberInput, MercadoPagoPayment },
  props: {
    hash: {
      type: String,
      default: null,
    },
    service: {
      type: Object,
      default: null
    },
    redirect: {
      type: String,
      default: null
    },
    mode: {
      type: String,
      default: 'payment'
    },
    publicKey: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    gateway: {
      type: String,
      default: 'educatena/stripe'
    },
    model: {
      type: Object,
      default: null
    },
    termsAccepted: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    // State Flags
    secretInjected: false,
    missingSecret: false,
    secret: null,

    // Data
    name: '',
    email: '',
    phone: '',

    inputDebounceTimeout: null
  }),

  watch: {
    name(value) {
      this.emitInput({
        name: value
      })
    },
    email(value) {
      this.emitInput({
        email: value
      })
    },
    phone(value) {
      this.emitInput({
        phone: value
      })
    },
  },

  computed: {
    invalidForm() {
      return this.errors.any() || this.emptyFields
    },
    emptyFields() {
      return this.isEmpty(this.name) || this.isEmpty(this.email) || this.isEmpty(this.phone)
    },
    gatewayMercadoPago() {
      return this.gateway === 'omnipay/mercado-pago'
    },
    gatewayStripe() {
      return this.gateway === 'educatena/stripe'
    },
    activeUser() {
      return this.$store.state.AppActiveUser
    },
    userName(){
      return this.activeUser ? this.activeUser.displayName : null
    },
    userEmail(){
      return this.activeUser ? this.activeUser.email : null
    },
    disableFieldActiveUser(){
      return (this.userName && this.userEmail)
    }
  },

  methods: {
    emitInput(payload) {
      const update = payload

      if (!this.isEmpty(this.name)) {
        update.name = this.name
      }

      if (!this.isEmpty(this.email)) {
        update.email = this.email
      }

      if (!this.isEmpty(this.phone)) {
        update.phone = this.phone
      }

      if (this.inputDebounceTimeout) {
        clearTimeout(this.inputDebounceTimeout)
      }

      this.inputDebounceTimeout = setTimeout(
        () => {
          this.$emit('input', update)
        },
        800
      )
    },
    // Utilities
    paymentComponent() {
      return this.$refs.payment || null
    },
    // Component Lifecycle
    stripePaymentLoaded(success) {
      this.$vs.loading.close()
      this.secretInjected = success
    },
    loadSecret(secret) {
      this.secret = secret
    },
    mountStripe() {
      this.$refs.payment.mountPaymentElement()
    },
    clearForm() {
      this.$refs.payment.clear()
      this.name = ''
      this.email = ''
      this.phone = ''
    },
    // Main Actions
    proccessCheckout() {
      this.$vs.loading()
      const billingDetails = {
        name: this.name,
        email: this.email,
        phone: this.phone,
      }
      this.$refs.payment.handleSubmit(billingDetails, this.redirect)
    },
    checkoutSuccess(response) {
      this.$emit('submit', response, {
        name: this.name,
        email: this.email,
        phone: this.phone,
      })
    },
    setActiveUser(){
      this.email = this.userEmail
      this.name = this.userName
    }
  },
  beforeMount() {
    this.setActiveUser()
  },
  mounted() {
    if (!this.gatewayStripe) {
      this.secretInjected = true
    }
  }
}
</script>

<style lang="scss">
// ._plan-checkout-page {
//   & .__side {
//     flex: 1; /* additionally, equal width */

//   }
// }
</style>
