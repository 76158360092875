import Rest from '../Rest'

export default class PlanService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/plan'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  lookupHash(hash: string) {
    return this.get('/', {
      h: hash
    })
  }

  getIntent(intentType: string, planId: number) {
    return this.get(`/${planId}/intent/${intentType}`)
  }

  confirmPayment(planId: number, intentId: String, secret: any) {
    return this.post(`/${planId}/payment/${intentId}/confirm`, {
      secret
    })
  }

  successStripePayment(planId: number, paymentIntent: any, payload: any) {
    return this.post(`/${planId}/payment/success`, {
      intent: paymentIntent,
      data: payload
    })
  }

  successMercadoPagoPayment(planId: number, cardData, billingDetails) {
    return this.post(`/${planId}/payment/mercado-pago`, {
      card_data: cardData,
      billing_details: billingDetails
    })
  }

  updateMetadata(planId: number, intentId: string, payload: any) {
    return this.put(`/${planId}/payment/${intentId}/metadata`, payload)
  }
}