var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hashLoaded
    ? _c(
        "div",
        [
          _c("payment-intent-provider", {
            attrs: {
              "model-id": _vm._.get(_vm.model, "id"),
              service: _vm.service,
              "intent-type": _vm.mode,
              disabled: _vm.gatewayMercadoPago,
            },
            on: {
              loading: function ($event) {
                return _vm.$vs.loading()
              },
              loaded: function ($event) {
                return _vm.$vs.loading.close()
              },
              secret: _vm.injectSecret,
              intent: function ($event) {
                _vm.paymentIntent = $event
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (intentProvider) {
                    return [
                      _c(
                        "product-checkout",
                        {
                          ref: "checkout",
                          attrs: {
                            publicKey: intentProvider.publicKey,
                            hash: _vm.hash,
                            model: _vm.model,
                            service: _vm.service,
                            loading: _vm.loading,
                            mode: _vm.mode,
                            gateway: _vm.gateway,
                            disabled: !_vm.termsAccepted,
                            redirect: _vm._.get(_vm.model, "redirect_url"),
                            "terms-accepted": _vm.termsAccepted,
                          },
                          on: {
                            submit: _vm.proccessCheckout,
                            input: _vm.updateMetadata,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "info",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "pt-2 flex flex-wrap justify-left",
                                      },
                                      [
                                        _c(
                                          "vs-checkbox",
                                          {
                                            staticClass: "mb-3",
                                            model: {
                                              value: _vm.termsAccepted,
                                              callback: function ($$v) {
                                                _vm.termsAccepted = $$v
                                              },
                                              expression: "termsAccepted",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "eu-li-e-concordo-com-estes"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "float-left",
                                            attrs: {
                                              href:
                                                _vm.model.terms_url ||
                                                "https://educatena.com.br/terms-service",
                                              target: "_blank",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("termos-e-condicoes")
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("vx-card", [
                                _c(
                                  "div",
                                  { staticClass: "flex flex-col xl:gap-4 p-4" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "font-bold text-base xl:text-2xl text-center",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm._.get(_vm.model, "name")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("vs-divider", { staticClass: "w-full" }),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "font-bold text-4xl text-center",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.priceValueFormatted) + " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("vx-card", { staticClass: "mt-4" }, [
                                _c(
                                  "div",
                                  { staticClass: "flex flex-col xl:gap-4 p-4" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "__description" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "text-sm xl:text-base text-center",
                                          },
                                          [
                                            _c("div", {
                                              directives: [
                                                {
                                                  name: "html-safe",
                                                  rawName: "v-html-safe",
                                                  value: _vm._.get(
                                                    _vm.model,
                                                    "description"
                                                  ),
                                                  expression:
                                                    "_.get(model, 'description')",
                                                },
                                              ],
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              3731903375
            ),
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }