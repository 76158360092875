<template>
  <div class="flex flex-col sm:flex-row gap-2 sm:items-center">
    <div class="w-full">
      <form class="w-full" id="payment-form">
        <div id="payment-element"/>
        <div id="payment-message" v-if="message">{{ message }}</div>
      </form>
    </div>
  </div>
</template>

<script>
import loadScript from 'load-script'
export default {
  props: {
    secret: {
      type: String,
      default: null
    },
    /**
     * Stripe mode.
     * 'setup' for when you only want to save the billing details.
     * 'payment' for when you want to do a single charge right away.
     */
    mode: {
      type: String,
      default: 'payment'
    },
    publicKey: {
      type: String,
      default: null
    }
  },
  data: () => ({
    stripeInstance: null,
    paymentElement: null,
    stripeElements: null,
    loaded: false,
    message: null,
  }),
  watch: {
    secret(value) {
      this.stripeBoot(value)
    }
  },
  methods: {
    stripeBoot(secret) {
      if (this.publicKey) {
        // eslint-disable-next-line no-undef
        this.stripeInstance = Stripe(this.publicKey)
      }
      if (this._.isString(secret) && secret.length > 0) {
        this.setupStripeElements(secret)
      }
    },
    clear() {
      if (this.paymentElement) {
        this.paymentElement.clear()
      }
    },
    showMessage(txt) {
      this.$vs.dialog({
        color: 'danger',
        title: 'Alerta!',
        acceptText: 'OK',
        text: `${txt}`
      })
    },
    setupStripeElements(secret) {
      if (this._.isString(secret)) {
        this.stripeElements = this.stripeInstance.elements({
          clientSecret: secret,
          locale: 'pt-BR'
        })
        this.mountPaymentElement()
        this.$emit('loaded', true)
      } else {
        this.$emit('loaded', false)
      }
    },
    makePaymentElement() {
      return this.stripeElements.create('payment', {
        fields: {
          billingDetails: {
            name: 'never',
            email: 'never',
          }
        }
      })
    },
    mountPaymentElement() {
      this.paymentElement = this.makePaymentElement()
      this.paymentElement.mount('#payment-element')
    },
    async handleSubmit(billingDetails, redirectUrl = null) {

      const email = this._.get(billingDetails, 'email')
      const name = this._.get(billingDetails, 'name')

      if (redirectUrl) {
        redirectUrl = `${redirectUrl}?email=${encodeURIComponent(email)}&name=${encodeURIComponent(name)}`
      }
      
      const stripeMethodName = `confirm${this._.upperFirst(this.mode)}`

      if (this._.isFunction(this.stripeInstance[stripeMethodName])) {

        const payload = {
          elements: this.stripeElements,
          confirmParams: {
            payment_method_data: {
              billing_details: {
                ...billingDetails
              }
            }
          },
          redirect: 'if_required'
        }

        if (redirectUrl) {
          payload.confirmParams.return_url = redirectUrl
        }

        const error = (response) => {
          const errorResponse = this._.get(response, 'error')

          const type = this._.get(errorResponse, 'type') || null
          const message = this._.get(errorResponse, 'message') || null
          if (type === 'card_error' || type === 'validation_error') {
            this.showMessage(message)
          } else if (type !== null) {
            this.showMessage(this.$t('erro-inesperado'))
          }

          if (errorResponse) {
            this.$emit('error', errorResponse)
            return errorResponse
          } else {
            return null
          }
        }

        return this.stripeInstance[stripeMethodName](payload).then(
          response => {
            response.redirect = redirectUrl
            this.$emit('success', response)

            const errorResult = error(response)
            
            return errorResult || response
          }, error
        ).finally(() => {
          this.$emit('finish')
        })
      } else {
        this.$emit('error', {
          message: this.$t('nao-e-possivel-confirmar-uma-intencao-desconhecida')
        })
      }
    }
  },
  beforeMount() {
    loadScript('https://js.stripe.com/v3', () => {
      this.stripeBoot(this.secret)
    })
  },
  mounted() {
  },
  beforeDestroy() {
    if (this.paymentElement) {
      this.paymentElement.destroy()
    }
  }
}
</script>

<style lang="scss">
  #payment-element {
    width: 100%;
    & > div,
    & iframe {
      width: 100%;
    }
  }
</style>