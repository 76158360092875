<template>
  <div class="wrapper">
    <card-form
      :form-data="formData"
      @input-card-number="updateCardNumber"
      @input-card-name="updateCardName"
      @input-card-month="updateCardMonth"
      @input-card-year="updateCardYear"
      @input-card-cvv="updateCardCvv"
    >
      <template #submit="submit">
        <slot name="submit" :actions="submit.actions" :state="submit.state">
          <input :disabled="submit.state.invalid" type="submit" @click="submit.actions.invaildCard" value="Enviar">
        </slot>
      </template>
    </card-form>
    <!-- backgroundImage="https://images.unsplash.com/photo-1572336183013-960c3e1a0b54?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=80" -->
  </div>
</template>

<script>
import CardForm from './components/CardForm'
export default {
  name: 'interactive-paycard',
  components: {
    CardForm
  },
  data () {
    return {
      formData: {
        cardName: '',
        cardNumber: '',
        cardMonth: '',
        cardYear: '',
        cardCvv: ''
      }
    }
  },
  props: {
    formData: {
      type: Object,
      default: () => ({
        cardName: '',
        cardNumber: '',
        cardMonth: '',
        cardYear: '',
        cardCvv: ''
      })
    }
  },
  methods: {
    updateCardNumber (val) {
      this.$emit('input', this.formData)
    },
    updateCardName (val) {
      this.$emit('input', this.formData)
    },
    updateCardMonth (val) {
      this.$emit('input', this.formData)
    },
    updateCardYear (val) {
      this.$emit('input', this.formData)
    },
    updateCardCvv (val) {
      this.$emit('input', this.formData)
    }
  },
  mounted () {
    // this.$i18n.locale = navigator.language
  },
  model: {
    prop: 'formData',
    event: 'input'
  }
}
</script>

<style lang="scss">
@import 'assets/style.scss';
</style>